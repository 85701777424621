<template>
  <ul class="menu-nav">
    <li>
      <i class="icon fas fa-calendar-alt"></i>
      {{ new Date() | moment("Do MMMM  YYYY") }}
    </li>
    <div class="dash">-</div>
    <li class="time_hijri">
      {{ time_hijiri }}
    </li>
    <li>
      <i class="icon far fa-clock"></i>
      <!-- {{time_now >= 12 ? ' PM ' : ' AM '}} -->
      {{ time_now | moment("hh:mm:ss a")}}
      </li>
  </ul>
</template>

<script>
var moment = require("moment-hijri");

export default {
  name: "KTMenu",
  data() {
    return {
      time_hijiri: null,
      time_now: null,
    };
  },

  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
  mounted() {
    let today = new Date();
    let date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    this.time_hijiri = moment(date, "YYYY-M-D").format("iD iMMMM iYYYY");

 
  
 
  },
  created () {
    
   setInterval(() => {
    //  let today = new Date();
		  this.time_now= new Date();
  
  }, 1000)
  }
};
</script>
