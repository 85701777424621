<template>
  <!-- begin:: Aside -->
  <div class="brand flex-column-auto" id="kt_brand" ref="kt_brand">
    <div class="brand-logo">
      <router-link to="/">
        <img
          v-if="base_URL.includes('fehm')"
          alt="Logo"
          src="/media/fehm_logo.png"
        />
        <img
          v-else-if="base_URL.includes('mcit')"
          alt="Logo"
          src="/media/logo.png"
        />

        <img v-else alt="Logo" src="/media/Logos/manash.png" />
      </router-link>
    </div>
    <div class="brand-tools" v-if="allowMinimize">
      <button class="brand-toggle btn btn-sm px-0" @click="toogle_sideNav()">
        <span class="svg-icon svg-icon svg-icon-xl">
          <inline-svg
            class="svg-icon"
            src="/media/svg/icons/Navigation/Angle-double-left.svg"
          />
        </span>
      </button>
    </div>
  </div>
  <!-- end:: Aside -->
</template>

<style lang="scss" scoped>
.aside-toggle {
  outline: none;
}
</style>

<script>
import { mapGetters } from "vuex";
import objectPath from "object-path";
import KTLayoutBrand from "@/assets/js/layout/base/brand.js";
import KTLayoutAsideToggle from "@/assets/js/layout/base/aside-toggle.js";

export default {
  name: "KTBrand",
  data() {
    return {
      CurentRout: "",
      base_URL: "",
    };
  },
  mounted() {
    // Init Brand Panel For Logo
    KTLayoutBrand.init(this.$refs["kt_brand"]);

    // Init Aside Menu Toggle
    KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
  },
  watch: {
    $route(to, from) {
      this.CurentRout = this.$route.path;

      if (this.$route.path.includes("mt/")) {
        var kt_aside = document.getElementById("kt_aside");
        var kt_wrapper = document.getElementById("kt_wrapper");
        var kt_header = document.getElementById("kt_header");
        var new_meeting_btn = document.getElementById("new_meeting");
        var new_meeting_not_show_btn = document.getElementById(
          "new_meeting_not_show"
        );
        kt_aside.classList.add("aside_smalle");
        kt_wrapper.classList.add("kt_wrapper_smalle");
        kt_header.classList.add("kt_header_small");
        new_meeting_btn.classList.toggle("not_show");
        new_meeting_not_show_btn.classList.toggle("not_show");
      }
    },
  },
  methods: {
    siteLogo() {
      const menuAsideLeftSkin = this.layoutConfig("brand.self.theme");
      // set brand logo
      const logoObject = this.layoutConfig("self.logo");

      let logo;
      if (typeof logoObject === "string") {
        logo = logoObject;
      }
      if (typeof logoObject === "object") {
        logo = objectPath.get(logoObject, menuAsideLeftSkin + "");
      }
      if (typeof logo === "undefined") {
        const logos = this.layoutConfig("self.logo");
        logo = logos[Object.keys(logos)[0]];
      }
      return process.env.BASE_URL + logo;
    },
    toogle_sideNav() {
      var kt_aside = document.getElementById("kt_aside");
      var kt_wrapper = document.getElementById("kt_wrapper");
      var kt_header = document.getElementById("kt_header");
      var new_meeting_btn = document.getElementById("new_meeting");
      var new_meeting_not_show_btn = document.getElementById(
        "new_meeting_not_show"
      );
      kt_aside.classList.toggle("aside_smalle");
      kt_wrapper.classList.toggle("kt_wrapper_smalle");
      kt_header.classList.toggle("kt_header_small");
      new_meeting_btn.classList.toggle("not_show");
      new_meeting_not_show_btn.classList.toggle("not_show");
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    allowMinimize() {
      return !!this.layoutConfig("aside.self.minimize.toggle");
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    if (this.$route.path.includes("mt/")) {
      var kt_aside = document.getElementById("kt_aside");
      var kt_wrapper = document.getElementById("kt_wrapper");
      var kt_header = document.getElementById("kt_header");
      var new_meeting_btn = document.getElementById("new_meeting");
      var new_meeting_not_show_btn = document.getElementById(
        "new_meeting_not_show"
      );
      kt_aside.classList.add("aside_smalle");
      kt_wrapper.classList.add("kt_wrapper_smalle");
      kt_header.classList.add("kt_header_small");
      new_meeting_btn.classList.toggle("not_show");
      new_meeting_not_show_btn.classList.toggle("not_show");
    }
  },
  created() {
    this.base_URL = window.location.href;
    var kt_aside = document.getElementById("kt_aside");
    var kt_wrapper = document.getElementById("kt_wrapper");
    var kt_header = document.getElementById("kt_header");
    var new_meeting_btn = document.getElementById("new_meeting");
    var new_meeting_not_show_btn = document.getElementById(
      "new_meeting_not_show"
    );
    this.CurentRout = this.$route.path;
    if (this.$route.path.includes("mt/")) {
      kt_aside.classList.add("aside_smalle");
      kt_wrapper.classList.add("kt_wrapper_smalle");
      kt_header.classList.add("kt_header_small");
      new_meeting_btn.classList.toggle("not_show");
      new_meeting_not_show_btn.classList.toggle("not_show");
    }
  },
};
</script>
