<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">
    <!--begin: Notifications -->

    <vs-dropdown class="notification" v-if="!IsGust" vs-custom-content vs-trigger-click>
      <a class="a-icon" href.prevent>
        <img class="notification_img" src="/media/svg/bell.svg" />
        <span class="notificatin_count">0</span>
      </a>

      <vs-dropdown-menu  class="notification_menu">
        <vs-dropdown-item class="notification_item">
          لا يوجد إشعارات حتى الأن
        </vs-dropdown-item>
      </vs-dropdown-menu>
    </vs-dropdown>
    <!-- <KTQuickUser></KTQuickUser> -->
    <vs-dropdown vs-custom-content vs-trigger-click>
      <a class="a-icon" href.prevent>
        <img class="user_avatar" :src="IsGust?'/media/avatar_def.png':currentUserPersonalInfo.avatar" />

        <vs-icon class="" icon="expand_more"></vs-icon>
      </a>

      <vs-dropdown-menu class="user_menu">
        <vs-dropdown-item @click="$router.push('/edit-profile')" v-if="!IsGust">
          <i class="fas fa-user"></i>
          تعديل الحساب
        </vs-dropdown-item>
        <vs-dropdown-item class="log_out_item" divider @click="onLogout">
          <i class="fas fa-power-off"></i>
          تسجيل الخروج
        </vs-dropdown-item>
      </vs-dropdown-menu>
    </vs-dropdown>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<script>
// import KTDropdownNotification from "@/view/layout/extras/dropdown/DropdownNotification.vue";
import { mapGetters } from "vuex";
import i18nService from "@/core/services/i18n.service.js";
import { LOGOUT } from "@/core/services/store/auth.module";
import users from "@/http/requests/users";
export default {
  name: "KTTopbar",
  data() {
    return {
      languageFlag: "",
      languages: i18nService.languages,
      IsGust: false,
    };
  },
  components: {
    // KTDropdownNotification,
  },
  methods: {
    onLogout() {
      if (this.IsGust) {
        localStorage.clear();
         window.location.href = "/";
      } else {
        this.$store.dispatch(LOGOUT).then(() => {
          sessionStorage.clear("lastUrl");
          window.location.href = "/";
        });
      }
    },
    onLanguageChanged() {
      this.languageFlag = this.languages.find((val) => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    getLanguageFlag() {
      return this.onLanguageChanged();
    },
  },
  created() {
    if (window.localStorage.getItem("gust_token")) {
      this.IsGust = true;
    }
  },
};
</script>
