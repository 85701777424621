<template>
  <ul class="menu-nav">
    <router-link
      v-if="userData.roles.includes('admin')"
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate;">
          <i class="icon fas fa-home"></i>
          <span class="menu-text">نظرة عامة</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/meetings_list"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
          CurentRout.includes('meeting') && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon flaticon2-expand"></i> -->
          <i class="icon fas fa-calendar-alt"></i>
          <span class="menu-text">قائمة الاجتماعات</span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="userData.roles.includes('admin')"
      to="/groups"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
          CurentRout.includes('group') && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon flaticon2-expand"></i> -->
          <i class="icon fas fa-user"></i>
          <span class="menu-text">المجموعات</span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="userData.roles.includes('admin')"
      to="/members_list"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon flaticon2-expand"></i> -->
          <i class="icon fas fa-clipboard-list"></i>
          <span class="menu-text">قائمة الاعضاء</span>
        </a>
      </li>
    </router-link>
    <li class="ul_border"></li>
    <li v-if="!userData.roles.includes('viewer')">
      <button id="new_meeting" class="btn_Green btn_main btn_new" @click="GoToURL()">
        <!-- <img src="/media/svg/plus.svg" /> -->
        <i class="fas fa-plus"></i>
        <span> إجتماع جديد </span>
      </button>
       <button id="new_meeting_not_show" class="btn_Green btn_main btn_new not_show" @click="GoToURL()">
        <!-- <img src="/media/svg/plus.svg" /> -->
        <i  class="fas fa-plus m-0"></i>
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  name: "KTMenu",
  data() {
    return {
      CurentRout: "",
      userData: {},
    };
  },
  methods: {
    GoToURL() {
      let routeData = this.$router.resolve({name: "new_meeting"});
      window.open(routeData.href, "_blank");
    },
    //  hasActiveChildren() {
    //     console.log(this.$route.name)
    //   },
  },
  // beforeRouteUpdate(to, from, next) {
  //   next();
  //   console.log(this.$route.name)
  // },
  created() {
    this.userData = JSON.parse(localStorage.getItem("User_data"));
  },
  watch: {
    $route(to, from) {
      this.CurentRout = this.$route.path;
    },
  },
  //
};
</script>
<style scoped>
.ul_border {
  width: 65%;
  height: 1px;
  background: #a5a6b982;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 30px;
}
.btn_new {
  /* padding: 8px; */
  text-align: center;
  width: 65%;
  margin: auto;
  /* display: block; */
}
</style>

