var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[(_vm.userData.roles.includes('admin'))?_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":function($event){navigate;}}},[_c('i',{staticClass:"icon fas fa-home"}),_c('span',{staticClass:"menu-text"},[_vm._v("نظرة عامة")])])])]}}],null,false,2388007456)}):_vm._e(),_c('router-link',{attrs:{"to":"/meetings_list"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
        _vm.CurentRout.includes('meeting') && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"icon fas fa-calendar-alt"}),_c('span',{staticClass:"menu-text"},[_vm._v("قائمة الاجتماعات")])])])]}}])}),(_vm.userData.roles.includes('admin'))?_c('router-link',{attrs:{"to":"/groups"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
        _vm.CurentRout.includes('group') && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"icon fas fa-user"}),_c('span',{staticClass:"menu-text"},[_vm._v("المجموعات")])])])]}}],null,false,2735556123)}):_vm._e(),(_vm.userData.roles.includes('admin'))?_c('router-link',{attrs:{"to":"/members_list"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"icon fas fa-clipboard-list"}),_c('span',{staticClass:"menu-text"},[_vm._v("قائمة الاعضاء")])])])]}}],null,false,336804172)}):_vm._e(),_c('li',{staticClass:"ul_border"}),(!_vm.userData.roles.includes('viewer'))?_c('li',[_c('button',{staticClass:"btn_Green btn_main btn_new",attrs:{"id":"new_meeting"},on:{"click":function($event){return _vm.GoToURL()}}},[_c('i',{staticClass:"fas fa-plus"}),_c('span',[_vm._v(" إجتماع جديد ")])]),_c('button',{staticClass:"btn_Green btn_main btn_new not_show",attrs:{"id":"new_meeting_not_show"},on:{"click":function($event){return _vm.GoToURL()}}},[_c('i',{staticClass:"fas fa-plus m-0"})])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }